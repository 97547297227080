export default class AboutSwiper {
  constructor(obj) {
    this.elmMain = document.querySelector(obj.main)
    this.elmNavPrev = document.querySelector(obj.navigation.prev)
    this.elmNavNext = document.querySelector(obj.navigation.next)
  }
  slide(_next_or_prev) {
    const $main = $(`#${this.elmMain.id}`)
    if (_next_or_prev === 'next') {
      $main.animate({
        scrollLeft: $main.scrollLeft() + 300
      })
    } else if (_next_or_prev === 'prev') {
      $main.animate({
        scrollLeft: $main.scrollLeft() - 300
      })
    }
  }
  addEvent() {
    this.elmNavNext.addEventListener('click', () => {
      this.slide('next')
    })
    this.elmNavPrev.addEventListener('click', () => {
      this.slide('prev')
    })
  }
  init() {
    this.addEvent()
  }
}