export default class Gallery {
  constructor(obj) {
    this.elmMain = document.querySelector(obj.main.id)
    this.elmMainImg = document.querySelector(`${obj.main.id} ${obj.main.imgSelector}`)
    this.elmMainCaption = document.querySelector(`${obj.main.id} ${obj.main.captionSelector}`)
    this.elmThumb = document.querySelector(obj.thumbs.id)
    this.elmThumbItems = [...document.querySelectorAll(`${obj.thumbs.id} ${obj.thumbs.thumbSelector}`)]
    this.elmNavPrev = document.querySelector(obj.navigation.prev)
    this.elmNavNext = document.querySelector(obj.navigation.next)
  }
  toggleVisivle(_elm) {
    _elm.classList.toggle('__show')
  }
  changeImg(_elm, _value) {
    _elm.setAttribute('src', _value)
  }
  changeCaption(_elm, _vlaue) {
    _elm.textContent = _vlaue
  }
  thumbSlide(_next_or_prev) {
    const $thumb = $(`#${this.elmThumb.id}`)
    const thumbWidth = this.elmThumbItems[0].clientWidth
    if (_next_or_prev === 'next') {
      $thumb.animate({
        scrollLeft: $thumb.scrollLeft() + thumbWidth
      })
    } else if (_next_or_prev === 'prev') {
      $thumb.animate({
        scrollLeft: $thumb.scrollLeft() - thumbWidth
      })
    }
  }
  addEvent() {
    /**
     * ナビゲーション
     */
    this.elmNavNext.addEventListener('click', () => {
      this.thumbSlide('next')
    })
    this.elmNavPrev.addEventListener('click', () => {
      this.thumbSlide('prev')
    })
    /**
     * サムネイルクリック => 画像とキャプションを切り替える
     */
    this.elmThumbItems.forEach(_elm => {
      _elm.addEventListener('click', (_ev) => {
        const evTarget = _ev.currentTarget
        this.toggleVisivle(this.elmMainImg)
        this.toggleVisivle(this.elmMainCaption)
        setTimeout(() => {
          const src = evTarget.querySelector('img').getAttribute('src')
          const caption = evTarget.getAttribute('data-caption')
          this.changeImg(this.elmMainImg, src)
          this.changeCaption(this.elmMainCaption, caption)
          this.toggleVisivle(this.elmMainImg)
          this.toggleVisivle(this.elmMainCaption)
        }, 200);
      })
    })
  }
  init() {
    this.addEvent()
  }
}