import SmoothScroll from './SmoothScroll'
import AboutSwiper from './AboutSwiper'
import Gallery from './Gallery'
import Fm from './Fm'
import FmValidate from './FmValidate'

const init = () => {
  /**
   * SmoothScroll
   */
  (() => {
    const smoothScroll = new SmoothScroll({
      targetClass: 'js-smooth-scroll',
      scrollBuffer: window.innerWidth > 750 ? 0 : 0
    })
    smoothScroll.init();
  })();

  /**
   * AboutSwiper
   */
  (() => {
    const aboutSwiper = new AboutSwiper({
      main: '#js-about-swiper-main',
      navigation: {
        prev: '#js-about-swiper-btn-prev',
        next: '#js-about-swiper-btn-next',
      },
    })
    aboutSwiper.init();
  })();

  /**
   * Gallery
   */
  (() => {
    const gallery = new Gallery({
      main: {
        id: '#js-gallery-main',
        imgSelector: 'img',
        captionSelector: '.p-gallery__caption',
      },
      thumbs: {
        id: '#js-gallery-thumbs',
        thumbSelector: '.p-gallery-thumbs__item',
      },
      navigation: {
        prev: '#js-gallery-nav-prev',
        next: '#js-gallery-nav-next',
      },
    })
    gallery.init();
  })();

  /**
   * Form
   */
  (() => {
    const fm = new Fm({
      main: {
        id: '#fm-submit',
      },
      input: {
        username: '.fm-username',
        email: '.fm-email',
        tel: '.fm-tel',
        locations: '.fm-locations',
        radio: 'use_radio',
        contents: '.fm-contents',
      },
    })
    fm.init();
  })();


  /**
   * KeyUp
   */
  (() => {
    const fmValidate = new FmValidate({
      input: {
        username: '.fm-username',
        email: '.fm-email',
        // contents: '.fm-contents',
      },
    })
    fmValidate.init();
  })();

  // /**
  //  * Scroll Animation
  //  */
  // (() => {
  //   const trigger = new ScrollTrigger.default()
  //   trigger.add('[data-scroll-trigger]', {
  //     once: true,
  //     offset: {
  //       element: {
  //         x: 0,
  //         y: (trigger, rect, direction) => {
  //           return 0.2
  //         }
  //       },
  //       viewport: {
  //         x: 0,
  //         y: (trigger, frame, direction) => {
  //           return trigger.visible ? 0 : 0.2
  //         }
  //       }
  //     },
  //     toggle: {
  //       class: {
  //         in: '__visible',
  //         out: '__invisible'
  //       },
  //       callback: {
  //         out: (trigger) => {}
  //       }
  //     },
  //   })
  // })();

}

window.addEventListener('DOMContentLoaded', () => {
  init()
  document.querySelector('body').classList.add('__visible')
})
