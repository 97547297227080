import smoothscroll from 'smoothscroll-polyfill';

export default class SmoothScroll {
  constructor(obj) {
    this.targetClass = obj.targetClass
    this.scrollBuffer = obj.scrollBuffer
  }
  addEvent() {
    smoothscroll.polyfill(); // 古いブラウザ用のpolyfill
    document.addEventListener('click', e => {
      const target = e.target
      if (!target.classList.contains(this.targetClass)) return;
      e.preventDefault();
      const targetId = target.hash
      const targetElement = document.querySelector(targetId)
      const rectTop = targetElement.getBoundingClientRect().top
      const offsetTop = window.pageYOffset
      const buffer = this.scrollBuffer
      const top = rectTop + offsetTop - buffer
      window.scrollTo({
        top,
        behavior: 'smooth'
      })
    })
  }
  init() {
    this.addEvent()
  }
}