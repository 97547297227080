export default class Fm {
  constructor(obj) {
    this.fmSubmit = document.querySelector(obj.main.id)
    this.username = document.querySelector(obj.input.username)
    this.email = document.querySelector(obj.input.email)
    this.tel = document.querySelector(obj.input.tel)
    this.locations = document.querySelector(obj.input.locations)
    this.radio = document.getElementsByName(obj.input.radio)
    this.contents = document.querySelector(obj.input.contents)
  }
  findCheckedElm(radio) {
    let checkedElm
    for (let i = 0; i < radio.length; i++) {
      if (radio[i].checked) {
        checkedElm = radio[i]
        break
      }
    }
    return checkedElm
  }
  addEvent() {
    this.fmSubmit.addEventListener('click', (e) => {
      e.preventDefault()
      const data = {
        'username': this.username.value,
        'email': this.email.value,
        'tel': this.tel.value,
        'locations': this.locations.value,
        'radio': this.findCheckedElm(this.radio).value,
        'contents': this.contents.value
      }

      if (data.username === '' || data.email === '' || data.radio === '') {
        e.target.style.backgroundColor = '#d70035'
        e.target.value = '失敗 必須項目を入力してください'
        return
      }

      $.ajax(
        {
          url: './fm.php',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          beforeSend: function() {
            e.target.value = '送信中'
          }
        })
        .done(function(data){
          window.location.href = './complete.html';
        })
        .fail(function(data){
            e.target.style.backgroundColor = '#d70035'
            e.target.value = '失敗 もう一度お試しください'
        })
    })
  }
  init() {
    this.addEvent()
  }
}

