export default class FmValidate {
  constructor(obj) {
    this.username = document.querySelector(obj.input.username)
    this.email = document.querySelector(obj.input.email)
    // this.contents = document.querySelector(obj.input.contents)
  }
  prohibitSendingOnPressEnter(e) {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      e.preventDefault()
    }
  }
  noticeEmptyItem(e) {
    e.target.style.border = 'solid 1px #d5d5d5'
    if (this.isEmpty(e)) {
      e.target.style.border = 'solid 2px #d70035'
    }
  }
  isZenkaku(e) {
    return (e.keyCode && e.keyCode === 229)
  }
  isEmpty(e) {
    return (e.target.value === '')
  }
  addEvent() {
    this.email.addEventListener('keydown', (e) => {
      this.prohibitSendingOnPressEnter(e)
    })
    this.username.addEventListener('keydown', (e) => {
      this.prohibitSendingOnPressEnter(e)
    })
    // this.contents.addEventListener('keydown', (e) => {
    //   this.prohibitSendingOnPressEnter(e)
    // })
    this.username.addEventListener('focusout', (e) => {
      this.noticeEmptyItem(e)
    })
    this.email.addEventListener('focusout', (e) => {
      this.noticeEmptyItem(e)
    })
    // this.contents.addEventListener('focusout', (e) => {
    //   this.noticeEmptyItem(e)
    // })
  }
  init() {
    this.addEvent()
  }
}


